import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  Typography,
  Pagination,
} from "@mui/material";
import axios from "axios";
import io from "socket.io-client";
import FilterLeadClaims from "./leadClaims/FilterLeadClaims";
import { useAuth } from "../contexts/AuthContext";
import DialogueBox from "./DialogueBox";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import SingleLeadClaimEntry from "./leadClaims/SingleLeadClaimEntry";

dayjs.extend(relativeTime);

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LeadClaimDashboard = () => {
  const [filteredClaimEvents, setFilteredClaimEvents] = useState([]);
  const [showFUBKEYDialog, setShowFUBKeyDialog] = useState(false);
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageForAgentPerformance, setCurrentPageForAgentPerformance] =
    useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalFilteredPages, setTotalFilteredPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [allClaimEvents, setAllClaimEvents] = useState([]);
  const [filterClaimErrorMessage, setFilterClaimErrorMessage] = useState("");
  const [totalPagesForPerformanceMetrics, setTotalPagesForPerformanceMetrics] =
    useState(1);
  const [
    filteredTotalPagesForPerformanceMetrics,
    setFilteredTotalPagesForPerformanceMetrics,
  ] = useState(1);
  const [filterAgentPerformanceMetrics, setFilterAgentPerformanceMetrics] =
    useState([]);
  const [updateMessage, setUpdateMessage] = useState("");

  const mergeAndSortEvents = useCallback((existingEvents, newEvents) => {
    const allEvents = [...existingEvents, ...newEvents];
    const uniqueEventsMap = new Map();

    allEvents.forEach((event) => {
      const eventKey = `${event.leadId}-${event.eventTimestamp}-${event.claimType}-${event.claimedAt}`;
      if (
        !uniqueEventsMap.has(eventKey) ||
        new Date(event.eventTimestamp) >
          new Date(uniqueEventsMap.get(eventKey).eventTimestamp)
      ) {
        uniqueEventsMap.set(eventKey, event);
      }
    });

    return Array.from(uniqueEventsMap.values()).sort((a, b) => {
      return (
        new Date(b.eventTimestamp).getTime() -
        new Date(a.eventTimestamp).getTime()
      );
    });
  }, []);

  const fetchClaimEvents = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/claim-events/simplified?page=${currentPage}`
      );

      if (!response.data || !Array.isArray(response.data.events)) {
        throw new Error("Invalid data structure received from API");
      }

      setFilteredClaimEvents((prevEvents) =>
        mergeAndSortEvents(prevEvents, response.data.events)
      );
      setAllClaimEvents((prevEvents) =>
        mergeAndSortEvents(prevEvents, response.data.events)
      );
      setTotalPages(response.data.totalPages);
      setTotalFilteredPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching claim events:", error);
      setError(`Failed to fetch claim events: ${error.message}`);
    }
  }, [currentPage, mergeAndSortEvents]);

  const fetchAgentPerformance = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/claim-events/agent-performance?page=${currentPageForAgentPerformance}`
      );
      setData(response.data.agentPerformance);
      setFilterAgentPerformanceMetrics(response.data.agentPerformance);
      setTotalPagesForPerformanceMetrics(response.data.totalPages);
      setFilteredTotalPagesForPerformanceMetrics(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching agent performance:", error);
      setError("Failed to fetch agent performance");
    }
  }, [currentPageForAgentPerformance]);

  const fetchAllData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await Promise.all([fetchClaimEvents(), fetchAgentPerformance()]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [fetchClaimEvents, fetchAgentPerformance]);

  useEffect(() => {
    if (user && user.subscriptionStatus === "active" && user.fubApiKey) {
      fetchAllData();
    } else if (
      user &&
      user.subscriptionStatus === "active" &&
      !user.fubApiKey
    ) {
      setShowFUBKeyDialog(true);
    }
  }, [user, fetchAllData]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_BASE_URL);

    socket.on("connect", () => {
      console.log("WebSocket connected");
    });

    socket.on("leadClaimed", (newEvent) => {
      console.log("Received leadClaimed event:", newEvent);
      setUpdateMessage("Updating real time Data");

      fetchAllData();

      setTimeout(() => setUpdateMessage(""), 3000);
    });

    socket.on("disconnect", () => {
      console.log("WebSocket disconnected");
    });

    return () => {
      console.log("Disconnecting WebSocket");
      socket.disconnect();
    };
  }, [fetchAllData]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handlePageChangeForAgentPerformance = (event, page) => {
    setCurrentPageForAgentPerformance(page);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (showFUBKEYDialog) {
    return <DialogueBox />;
  }

  return (
    <>
      {updateMessage && (
        <Box
          sx={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 9999,
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            padding: "10px 20px",
            borderRadius: "4px",
            boxShadow: 2,
          }}
        >
          <Typography>{updateMessage}</Typography>
        </Box>
      )}
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && !showFUBKEYDialog && (
        <Box sx={{ display: "flex", flexDirection: "column" }} width="100%">
          <FilterLeadClaims
            setFilteredClaimEvents={setFilteredClaimEvents}
            setFilterAgentPerformanceMetrics={setFilterAgentPerformanceMetrics}
            data={data}
            setFilterClaimErrorMessage={setFilterClaimErrorMessage}
            setTotalPages={setTotalPages}
            setTotalFilteredPages={setTotalFilteredPages}
            totalPages={totalPages}
            allClaimEvents={allClaimEvents}
            setFilteredTotalPagesForPerformanceMetrics={
              setFilteredTotalPagesForPerformanceMetrics
            }
            filteredTotalPagesForPerformanceMetrics={
              filteredTotalPagesForPerformanceMetrics
            }
            totalPagesForPerformanceMetrics={totalPagesForPerformanceMetrics}
          />
          {/* Agent performance metrics  */}
          <Box mt={6}>
            <Typography variant="h5" component="h5" gutterBottom>
              Agent Performance Metrics
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>AGENT</TableCell>
                    <TableCell>FTC CLAIMS</TableCell>
                    <TableCell>POND CLAIMS</TableCell>
                    <TableCell>REASSIGNED CLAIMS</TableCell>
                    <TableCell>RELEASED TO POND</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterAgentPerformanceMetrics?.length > 0 ? (
                    filterAgentPerformanceMetrics?.map((agent, index) => (
                      <TableRow key={index}>
                        <TableCell>{agent?.agentName}</TableCell>
                        <TableCell>{agent?.ftcClaims?.count}</TableCell>
                        <TableCell>{agent?.pondClaims?.count}</TableCell>
                        <TableCell>{agent?.reassignedClaims?.count}</TableCell>
                        <TableCell>
                          {agent?.releasedToPondClaims?.count}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display="flex" alignItems="center" justifyContent="end">
              <Pagination
                count={filteredTotalPagesForPerformanceMetrics}
                page={currentPageForAgentPerformance}
                onChange={handlePageChangeForAgentPerformance}
              />
            </Box>
          </Box>

          {/* Lead Claims Overview */}
          <Box>
            <Typography variant="h5" component="h5" gutterBottom>
              Lead Claims Overview
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>LEAD NAME</TableCell>
                    <TableCell>CLAIM TYPE</TableCell>
                    <TableCell>From Agent</TableCell>
                    <TableCell>To Agent</TableCell>
                    <TableCell>POND</TableCell>
                    <TableCell>Lead Stage</TableCell>
                    <TableCell>DATE</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredClaimEvents?.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        No data found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredClaimEvents?.map((event, index) => {
                      const uniqueKey = `${event.leadId}-${event.claimedAt}-${index}`;
                      const claimedAtFormatted = dayjs(
                        event.claimedAt
                      ).isBefore(dayjs().subtract(1, "day"))
                        ? dayjs(event.claimedAt).format("MMM D, YYYY")
                        : dayjs(event.claimedAt).fromNow();

                      return (
                        <SingleLeadClaimEntry
                          key={uniqueKey}
                          claimedAtFormatted={claimedAtFormatted}
                          event={event}
                        />
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="end">
            <Pagination
              count={totalFilteredPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default LeadClaimDashboard;
